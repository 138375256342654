<template>
  <div class="box">
    <div class="box-toolbar">
      <div class="box-item">
        <el-input
          placeholder="手机号码\用户名\ID"
          v-model="content"
          class="input-with-select"
          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-button
          icon="el-icon-search"
          circle
          @click="searchGo()"
        ></el-button>
      </div>
    </div>
    <el-table
      border
      height="70vh"
      v-loading="loading"
      :stripe="true"
      :data="lists"
    >
      <el-table-column
        width="90"
        class-name="text-mono"
        prop="id"
        label="Id"
      >
      </el-table-column>
      <el-table-column
        prop="username"
        label="用户名称"
      >
      </el-table-column>
      <el-table-column
        prop="sex"
        label="性别"
      >
      </el-table-column>
      <el-table-column
        prop="phone"
        width="150"
        label="电话号码"
      >
      </el-table-column>

      <el-table-column
        align="center"
        prop="des_"
        label="描述"
      >
      </el-table-column>

      <el-table-column
        align="center"
        prop="integral"
        label="积分增加/减少"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="surplus"
        label="剩余积分"
      >
      </el-table-column>

      <el-table-column
        class-name="text-mono"
        width="130"
        prop="created_at"
        label="创建时间"
      >
      </el-table-column>
    </el-table>
    <div class="bp-pages">
      <page
        :pageSize="limit"
        :count="count"
        @change="pageChange"
        @changeSize="changeSize"
      >
      </page>
    </div>
  </div>
</template>

<script>
import IntegralService from '@admin/services/IntegralService'
import pagination from '@admin/mixins/PaginationMixin'
import Page from '@admin/components/Pagination'

export default {
  name: 'IntegralLog',
  mixins: [pagination],
  components: { Page },
  data () {
    return {
      content: '',
      loading: true,
      function: function (param) {
        return IntegralService.integral(param)
      }
    }
  },
  methods: {
    async searchGo () {
      this.page = 1
      this.loading = true

      let param = {
        limit: this.limit,
        page: this.page,
        content: this.content
      }
      this.where = { content: this.content }
      const data = await this.function(param)

      if (data) {
        this.lists = data.data
        this.count = data.count
      }

      this.loading = false
    }
  }
}
</script>
<style lang='' scoped>
</style>
